import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import config from '../../../meta/config';
import { defaultData } from 'mock/data.js';

const SEO = ({
  canonical,
  cover,
  description,
  metaTitle,
  section,
  slug,
  title,
  url,
}) => {
  const postURL =
    url ||
    (slug && section ? `${config.siteUrl}/${section}/${slug}` : config.siteUrl);
  const realPrefix = config.pathPrefix !== '/' ? config.pathPrefix : '';
  const image = `${config.siteUrl}${realPrefix}${cover}`;
  const blogURL = `${config.siteUrl}${config.pathPrefix}`;

  const canonicalUrl = canonical || `${postURL}/`;

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt || '',
    },
    // [
    //   {
    //     '@context': 'http://schema.org',
    //     '@type': 'BreadcrumbList',
    //     itemListElement: [
    //       {
    //         '@type': 'ListItem',
    //         position: 1,
    //         item: {
    //           '@id': postURL,
    //           name: title,
    //           image,
    //         },
    //       },
    //     ],
    //   },
    //   {
    //     '@context': 'http://schema.org',
    //     '@type': 'BlogPosting',
    //     url: blogURL,
    //     name: title,
    //     alternateName: config.siteTitleAlt || '',
    //     headline: title,
    //     image: {
    //       '@type': 'ImageObject',
    //       url: image,
    //     },
    //     description,
    //   },
    // ],
  ];

  return (
    <Helmet titleTemplate={'%s | TikTalk.pl'} defaultTitle={'Boldare'}>
      {/* General tags */}
      <title>{title || metaTitle}</title>
      <meta name={'description'} content={description} />
      <meta name={'image'} content={image} />
      {canonicalUrl && <link rel={'canonical'} href={`${canonicalUrl}`} />}

      {/* OpenGraph tags */}
      <meta property={'og:url'} content={postURL} />
      <meta property={'og:type'} content={'article'} />
      <meta property={'og:title'} content={metaTitle} />
      <meta property={'og:description'} content={description} />
      <meta property={'og:image'} content={image} />

      {/* Twitter Card tags */}
      <meta name={'twitter:card'} content={'summary_large_image'} />
      <meta name={'twitter:creator'} content={defaultData.author || ''} />
      <meta name={'twitter:title'} content={metaTitle} />
      <meta name={'twitter:description'} content={description} />
      <meta name={'twitter:image'} content={image} />
    </Helmet>
  );
};

SEO.propTypes = {
  canonical: PropTypes.string,
  cover: PropTypes.string,
  description: PropTypes.string,
  metaTitle: PropTypes.string,
  section: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

SEO.defaultProps = {
  canonical: '',
  cover: config.siteCover,
  description: config.siteDescription,
  metaTitle: config.siteTitle,
  section: '',
  slug: '',
  title: '',
  url: '',
};

export default SEO;
