import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';

import { pages } from 'config/pages';

import { SEO } from '.';
import 'styles/main.scss';

const environment = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV;
const isProduction = environment.includes('production');

const Layout = ({ children, page, canonical, url }) => {
  let pathBase = null;

  if (typeof window !== 'undefined' && window.location) {
    const { pathname } = window.location;
    pathBase = pathname.split('/')[1];
  }

  const seoCanonical = canonical || page || pages.default;
  const { metaTitle, siteContent, siteCover, title } = page || pages.default;

  return (
    <>
      <Helmet />
      <div>
        <SEO
          title={title}
          metaTitle={metaTitle}
          description={siteContent}
          cover={siteCover}
          slug={pathBase}
          canonical={seoCanonical}
          url={url}
          cloudinary
        />
        <div>{children}</div>
        <CookieConsent
          buttonClasses={'btn btn--shadowed transition--fast link--borderless'}
          buttonText={'Okej'}
          containerClasses={'cookies__banner'}
          contentClasses={'text-capitalize'}
          cookieName={'gatsby-gdpr-google-analytics'}
          disableStyles={true}
          location={'none'}
        >
          Ta strona używa ciasteczek.
        </CookieConsent>
      </div>
    </>
  );
};

Layout.defaultProps = {
  page: '',
  canonical: '',
  cloudinary: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.object,
  canonical: PropTypes.string,
  cloudinary: PropTypes.bool,
};

export default Layout;
