import React, { useState, useEffect, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import { useOnClickOutside } from 'config/helpers';
import { Hamburger, Link } from 'components';
import SVG from 'react-inlinesvg';
import { classNames } from 'utils';
import { defaultData } from 'mock/data.js';
import './Navbar.scss';

import logo from 'assets/images/logo.svg';
import fb from 'assets/images/fb.svg';
import ig from 'assets/images/ig.svg';
import tt from 'assets/images/tt.svg';

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [top, setTop] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);
    setTop(currentScrollPos > 160);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <Fade top duration={1000} delay={0}>
      <header
        id={'navbar'}
        className={classNames('navbar', {
          open: open,
          visible: visible,
          top: !top,
        })}
        role={'banner'}
        open={open}
        setOpen={setOpen}
      >
        <div className={'navbar__top'} ref={node}>
          <div className={'navbar__logo'}>
            <h1 className={'navbar__logo__name'}>{defaultData.name}</h1>
            <Link
              to={'/'}
              additionalClass={'navbar__logo__img link--borderless'}
            >
              <SVG src={logo} />
            </Link>
            <Hamburger open={open} setOpen={setOpen} />
          </div>
          <div className={'navbar__nav'}>
            <nav className={'navbar__navigation navbar__navigation--left'}>
              <Link
                to={defaultData.donate}
                additionalClass={'navbar__navigation--link navbar__navigation--btn'}
                title={'Wesprzyj TikTalk'}
              >
                POSTAW KAWĘ
              </Link>
              <Link
                to={defaultData.yt}
                additionalClass={'navbar__navigation--link'}
                title={'TikTalk w YouTube.com'}
              >
                YouTube
              </Link>
              <Link
                to={`mailto:${defaultData.mail}`}
                additionalClass={'navbar__navigation--link'}
                title={'Napisz do mnie!'}
              >
                Kontakt
              </Link>
            </nav>
            <nav
              className={
                'navbar__navigation navbar__navigation--right text--medium'
              }
            >
              <ul className={'social'}>
                <li className={'social__item'}>
                  <Link
                    to={defaultData.tt}
                    additionalClass={'social__link'}
                    title={'Twitter'}
                  >
                    <SVG src={tt} />
                  </Link>
                </li>
                <li className={'social__item'}>
                  <Link
                    to={defaultData.fb}
                    additionalClass={'social__link'}
                    title={'Facebook – Ekipa TikTalk'}
                  >
                    <SVG src={fb} />
                  </Link>
                </li>
                <li className={'social__item'}>
                  <Link
                    to={defaultData.ig}
                    additionalClass={'social__link'}
                    title={'Instagram'}
                  >
                    <SVG src={ig} />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </Fade>
  );
};

export default Navbar;
