import config from '../../../meta/config';

export const pages = {
  default: {
    label: 'home',
    metaTitle: config.siteTitle,
    siteContent: config.siteDescription,
    siteCover: '/brand/tiktalk.jpg',
  },
  '404': {
    label: 'not-found',
    title: 'Sorry, nie ma takiej strony :('
  },
};
