module.exports = {
  // GENERAL
  siteUrl: 'https://tiktalk.pl',
  pathPrefix: '/',
  siteLogo: '/assets/images/logo.svg',
  // CMS
  siteTitle: 'Pierwszy program o zegarkach na polskim YouTube',
  siteTitleAlt: 'TikTalk.pl',
  siteDescription: 'TikTalk to kanał edukacyjny, dzięki któremu dowiesz się wszystkiego, co wypada wiedzieć o zegarkach. TikTalk to spotkanie tradycji i nowoczesności. To kompendium wiedzy o zegarkach w formie, jakiej jeszcze nie było! Prowadzi Krzysiek Humeniuk.',
  // THEME
  themeColor: '#ff8b00',
  backgroundColor: '#ffffff',
};
