import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import './BackgroundImage.scss';
import { classNames } from 'utils';
import { getImageAlt } from 'config/helpers';

const BackgroundImage = ({ src, alt, style, additionalClass }) =>
  typeof src === 'object' ? (
    <Img
      fluid={src}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        ...style,
      }}
      imgStyle={{
        objectPosition: 'center top',
      }}
      alt={alt || getImageAlt(src.originalName || src.src)}
      title={alt || getImageAlt(src.originalName || src.src)}
      className={classNames(
        'background-image',
        'background-image--optimized',
        additionalClass,
      )}
    />
  ) : (
    <div
      style={{ backgroundImage: `url(${src})`, ...style }}
      className={classNames(
        'background-image',
        'background-image--basic',
        additionalClass,
      )}
    />
  );

BackgroundImage.defaultProps = {
  additionalClass: '',
  alt: '',
  style: {},
};

BackgroundImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string,
  style: PropTypes.shape(),
  additionalClass: PropTypes.string,
};

export default BackgroundImage;
