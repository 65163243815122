import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link, Paragraph } from 'components';
import { defaultData, footerData } from 'mock/data.js';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className={'footer'}>
      <div className={'wrapper'}>
        <div className={'footer__columns text--medium size--default m-48'}>
          <div className={'footer__item'}>
            <nav className={'footer__item--nav'}>
              <ul className={'nav__list'}>
                <li className={'nav__list--link m-16'}>
                  <Link
                    to={defaultData.yt}
                    title={'TikTalk w YouTube.com'}
                  >
                    YouTube
                  </Link>
                </li>
                <li className={'nav__list--link m-16'}>
                  <Link
                    to={`mailto:${defaultData.mail}`}
                    title={'Napisz do mnie!'}
                  >
                    Kontakt
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className={'footer__copy text-center size--extrasmall'}>
          <Paragraph>
            {ReactHtmlParser(footerData.description)}
            <br />
            {ReactHtmlParser(footerData.descriptionSecond)}
          </Paragraph>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
