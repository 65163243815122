import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { classNames } from '../../utils';
import './Container.scss';
import { Heading, Paragraph, Link } from 'components';

const Container = ({
  additionalClass,
  backgroundShape,
  linkLabel,
  children,
  description,
  fullwidth,
  link,
  side,
  theme,
  title,
  top,
}) => {
  return top || side ? (
    <div
      className={classNames(additionalClass, {
        'container--equal m-56': top,
        'container--side': side,
      })}
    >
      <Heading
        type={2}
        size={'almosthuge'}
        additionalClass={classNames('container-heading text--bold', {
          'text--white': theme === 'light'
        })}
      >
        {ReactHtmlParser(title)}
      </Heading>
      <Paragraph
        size={'defaultbigger'}
        additionalClass={classNames('container-description', {
          'text--white': theme === 'light'
        })}
      >
        {ReactHtmlParser(description)}
      </Paragraph>
      {link && (
        <Link to={link} additionalClass={'text--white text--bold'} arrow>{linkLabel}</Link>
      )}
      {backgroundShape && (
        <div className={'container-background'}>
          <div className={'container-background--shape'} />
        </div>
      )}
    </div>
  ) : (
    <div
      className={classNames('w-container', additionalClass, {
        'w-container--fullwidth': fullwidth,
      })}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fullwidth: PropTypes.bool,
  side: PropTypes.bool,
  theme: PropTypes.string,
  top: PropTypes.bool,
};

Container.defaultProps = {
  additionalClass: '',
  children: null,
  fullwidth: false,
  side: false,
  theme: 'dark',
  top: false,
};

export default Container;
