// Basic classNames usage example:
// <div className={classNames('classname-that-should-always-appear', {
//  'conditionally-applied-classname': whenThisValueIsTruthyTheClassNameIsAddedToTheElement,
// })}>
// ------------------------------------------------------------------------------------------------
// You can also create classes dynamically based on value passed in some prop/variable like so:
// <div className={classNames('classname-that-should-always-appear', {
//  [`col--${columnWidth}`]: columnWidth,
// })}>
// ------------------------------------------------------------------------------------------------
// You can also pass an array if you feel like it ¯\_(ツ)_/¯
// <div className={classNames(['some-classname', {
//   'conditionally-applied-classname': whenThisValueIsTruthyTheClassNameIsAddedToTheElement,
//   [`col--${columnWidth}`]: columnWidth,
// }, 'some-other-classname'])}>
export const classNames = (...args) => {
  let classes = [];

  args
    .filter(Boolean) // filter out falsy values
    .forEach(arg => {
      const argType = typeof arg;

      if (Array.isArray(arg) && arg.length) {
        classes = [...classes, ...classNames(...arg)];
        return;
      }

      if (argType === 'object') {
        // iterate over received object and check if class name conditionals are truthy
        Object.entries(arg).forEach(item => {
          const [key, value] = item;

          if (Object.hasOwnProperty.call(arg, key) && value) {
            classes.push(key);
          }
        });
        return;
      }

      if (argType === 'string' || argType === 'number') {
        classes.push(arg);
      }
    });

  // remove duplicates and join all classes into a single string
  return Array.from(new Set(classes)).join(' ');
};

export const getYoutubeThumbnailUrl = imgSrc => {
  const youtubeVideoId = imgSrc.split('v=')[1];
  return `https://img.youtube.com/vi/${youtubeVideoId}/maxresdefault.jpg`;
};
