import React from "react";
import PropTypes from "prop-types";
import { Container } from "components";
import { classNames } from "utils";
import "./Section.scss";

const Section = ({
  additionalClass,
  containerClass,
  children,
  color,
  fullwidth,
  id,
  spaceless,
}) => (
  <section
    id={id}
    className={classNames(
      "section",
      {
        'section--spaceless': spaceless,
        [`section--${color}`]: color,
      },
      additionalClass
    )}
  >
    <Container
      fullwidth={fullwidth}
      additionalClass={containerClass}
    >
      {children}
    </Container>
  </section>
);

Section.propTypes = {
  additionalClass: PropTypes.string,
  containerClass: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fullwidth: PropTypes.bool,
  id: PropTypes.string,
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Section.defaultProps = {
  additionalClass: "",
  containerClass: "",
  color: "transparent",
  fullwidth: false,
  id: null,
  background: "",
  children: null,
};

export default Section;
