import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import icons from './icons-set.svg';
import './IconSVG.scss';

const IconSVG = ({ additionalClass, background, size, name, color }) => (
  <div
    className={classNames(
      'icon-svg__wrapper',
      {
        [`icon-svg--background-${background}`]: background,
        [`icon-svg__wrapper--${size}`]: size,
      },
      additionalClass,
    )}
  >
    <svg
      className={classNames('icon-svg', {
        [`icon-svg-${name}`]: name,
        [`icon-svg--color-${color}`]: color,
      })}
    >
      <use xlinkHref={`${icons}#icon-${name}`} />
    </svg>
  </div>
);

IconSVG.defaultProps = {
  size: 'medium',
  color: 'black',
  background: '',
  additionalClass: '',
};

IconSVG.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string,
  additionalClass: PropTypes.string,
};

export default IconSVG;
