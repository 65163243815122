import review from 'assets/images/review.jpg';
import coop from 'assets/images/coop.jpg';
import live from 'assets/images/live.jpg';
import trip from 'assets/images/trip.jpg';
import insta from 'assets/images/insta.jpg';
import pres from 'assets/images/pres.jpg';
import hero from 'assets/images/hero.png';
import thumb from 'assets/images/thumb.jpg';

// Default data + social media links
export const defaultData = {
  name: 'TikTalk',
  author: '@krzysiekhum',
  mail: 'wspolpraca@tiktalk.pl',
  fb: 'https://www.facebook.com/groups/ekipatiktalk',
  tt: 'https://twitter.com/TikTalkYT',
  ig: 'https://www.instagram.com/krzysiekhum',
  yt: 'https://youtube.com/@TikTalk',
  donate: 'https://tipply.pl/@tiktalk',
  website: 'https://tiktalk.pl',
};

// Hero
export const heroData = {
  title: 'Największy kanał<br/>o zegarkach<br/>w Polsce',
  description: `Krzysiek Humeniuk przedstawia TikTalk:`,
  image: hero,
  url: 'https://www.youtube.com/watch?v=2VdaZhB4mIE',
};

// Numbers
export const numbersData = {
  title: `Program TikTalk w&nbsp;liczbach`,
  description: `TikTalk po raz pierwszy pojawił się w&nbsp;internecie w 2016 roku. Od tamtego momentu, odcinki mojego programu oglądane są przez dziesiątki tysięcy widzów.`,
  numbers: [
    {
      value: '+72 000',
      subtitle: 'subskrybentów kanału',
    },
    {
      value: '230 000',
      subtitle: 'wyświetleń miesięcznie',
    },
    {
      value: '+28 000',
      subtitle: `członków <a class="link text--main" href="${defaultData.fb}" target="_blank" rel="noreferrer">Ekipy TikTalk</a>`,
    },
  ],
  date: '30 czerwca 2024',
};

// Latest
export const latestData = {
  title: 'Najnowszy odcinek TikTalk',
  description:
    'Program TikTalk ukazuje się na łamach platformy YouTube i porusza najważniejsze tematy związane z zegarkami oraz historią zegarmistrzostwa.',
  url: 'https://www.youtube.com/embed/videoseries?list=PLnRd-VZI8qglrt7-ZzmYNUzfgd9qyF-ZS',
  thumb: thumb,
  link: 'https://www.youtube.com/playlist?list=PLnRd-VZI8qglrt7-ZzmYNUzfgd9qyF-ZS',
  label: 'Zobacz wszystkie odcinki',
};

// About
export const aboutData = [
  {
    title: 'Partnerstwo',
    description: `Cenię sobie zarówno proste jak i złożone partnerstwa. Chcesz zrealizować tylko jeden film? <span class="text--bold">Nie ma problemu!</span> A może interesuje Cię stała współpraca? <span class="text--bold">Chętnie! :)</span> Dłuższe kampanie to więcej możliwości dotarcia do osób zainteresowanych Twoim produktem lub usługą.`,
    image: coop,
  },
  {
    title: 'Odcinek na żywo',
    description: `<a href="https://www.youtube.com/watch?v=B3UrccNplI0&list=PLnRd-VZI8qgmTE0YCNYwxqm1B9RaMhV7l" class="link text--main" target="_blank" rel="noreferrer">Zegarkowe Newsy</a> to odcinki na żywo, bardzo chętnie oglądane przez widzów TikTalk. Możemy razem stworzyć webinar, opowiedzieć o Twoim produkcie/usłudze albo zrobić sesję pytań i odpowiedzi z widzami. A wszystko to <span class="text--bold">tu i teraz!</span>`,
    image: live,
  },
  {
    title: 'Wyjazd sponsorowany',
    description: `Niektórych rzeczy nie da się pokazać na filmie czy w recenzji. Czasami trzeba zobaczyć je na własne oczy... a potem opowiedzieć o nich widzom TikTalk ;)`,
    image: trip,
  },
  {
    title: 'Zdjęcia na Instagramie',
    description: `Chcesz pokazać coś moim widzom, ale uważasz, że film to zbyt wiele? Żaden problem – możemy skorzystać z Instagrama albo któregoś z moich mediów społecznościowych.`,
    image: insta,
  },
  {
    title: 'Występy na wydarzeniach',
    description: `Oglądanie TikTalk na YouTube do niedawna było jedyną możliwością posłuchania, co mam do powiedzienia o zegarkach. Teraz mogę wystąpić na Twojej imprezie i przedstawić jakiś ciekawy temat.`,
    image: pres,
  },
  {
    title: 'Recenzje zegarków',
    description: `Od stycznia 2020 roku <span class="text--bold">całkowicie zrezygnowałem z tworzenia sponsorowanych/semi-sponsorowanych recenzji</span>. Jest to nie tylko odważna decyzja, ale również ogromna troska o branżę zegarkową w polskim (i światowym) internecie.`,
    image: review,
    isCancelled: true,
  },
];

// Contact
export const contactData = {
  title: 'Chcesz nawiązać współpracę z&nbsp;TikTalk?',
  description:
    'Nic prostszego! Wyślij mi maila i opisz w nim, co moglibyśmy razem zrobić.',
  button: {
    link: `mailto:${defaultData.mail}`,
    label: 'Wyślij wiadomość',
  },
};

export const footerData = {
  description: `Copyright © 2018-${new Date().getFullYear()}, TikTalk. Wszystkie prawa zastrzeżone`,
  descriptionSecond:
    'Niektóre linki umieszczone na tej stronie mogą być linkami afiliacyjnymi.',
};

export const notFoundData = {
  title: `Nie ma takiej strony :(`,
  description: `Strona, której szukasz nie istneje.`,
  link: 'Wróć na stronę główną',
};
