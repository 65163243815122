import { useEffect } from 'react';

export const isResolution = breakpoint =>
  (typeof window !== 'undefined' && window.innerWidth > breakpoint) === false;

export const getImageAlt = _fileName => {
  let fileName = _fileName;

  if (typeof _fileName === 'object' && _fileName.base64) {
    fileName = _fileName.base64;
  }

  if (fileName.includes('data:image')) {
    return '';
  }
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  },
  [ref, handler],
  );
};
