import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './Paragraph.scss';

const Paragraph = ({
  additionalClass,
  color,
  children,
  size,
  nested,
}) => {
  const classes = classNames(
    'paragraph',
    {
      [`text--${color}`]: color,
      [`paragraph--${size}`]: size,
    },
    additionalClass,
  );

  if (!children) {
    return null;
  }

  return nested ? (
    <div className={classes}>{children}</div>
  ) : (
    <p className={classes}>{children}</p>
  );
};

Paragraph.propTypes = {
  additionalClass: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  nested: PropTypes.bool,
};

Paragraph.defaultProps = {
  additionalClass: '',
  children: null,
  size: 'regular',
  color: 'default',
  nested: false,
};

export default Paragraph;
