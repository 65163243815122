import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconSVG } from 'components';
import GatsbyLink from 'gatsby-link';
import { classNames } from 'utils';
import './Link.scss';

class Link extends Component {
  render() {
    const {
      additionalClass,
      arrow,
      blank,
      button,
      children,
      shadow,
      to,
      underline,
      ...other
    } = this.props;
    const isInternal = /^\/(?!\/)/.test(to);

    let isExternal = true;
    if (typeof window !== 'undefined' && window.location) {
      isExternal = new RegExp(
        `^((f|ht)tps?:)?//(?!${window.location.host})`,
      ).test(to);
    }

    if ((to === '/' || isInternal) && !blank) {
      return (
        <GatsbyLink
          className={classNames(
            'link',
            {
              'btn': button,
              'btn--shadowed': shadow,
              'link--anim-arrow': arrow,
              link__underline: underline,
              [`link__underline--${underline}`]: underline,
            },
            additionalClass,
          )}
          to={to}
          {...other}
        >
          {children}
        </GatsbyLink>
      );
    }

    return (
      <a
        className={classNames(
          'link',
          {
            'btn': button,
            'btn--shadowed': shadow,
            'link--anim-arrow': arrow,
            link__underline: underline,
            [`link__underline--${underline}`]: underline,
          },
          additionalClass,
        )}
        href={to}
        rel={isExternal ? 'noopener noreferrer' : ''}
        target={isExternal || blank ? '_blank' : '_self'}
        {...other}
      >
        {children}
        {arrow && (
          <IconSVG name={'arrow-right'} color={'white'} />
        )}
      </a>
    );
  }
}

Link.propTypes = {
  additionalClass: PropTypes.string,
  arrow: PropTypes.bool,
  blank: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  to: PropTypes.string,
  underline: PropTypes.string,
};

Link.defaultProps = {
  additionalClass: '',
  arrow: false,
  blank: false,
  to: '',
  underline: '',
};

export default Link;
