import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './Heading.scss';

const Heading = ({
  additionalClass,
  background,
  children,
  type,
  color,
  size,
}) => {
  const HeadingTag = `h${type}`;

  return (
    <HeadingTag
      className={classNames(
        'heading',
        {
          [`heading--${size}`]: size,
          [`text--${color}`]: color,
          [`heading__bg`]: background,
        },
        additionalClass,
      )}
    >
      {children}
    </HeadingTag>
  );
};

Heading.defaultProps = {
  additionalClass: '',
  background: false,
  color: '',
  size: 'default',
  type: 3,
};

Heading.propTypes = {
  additionalClass: PropTypes.string,
  background: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
};

export default Heading;
